













































































































































































































































































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import CloseButton from '@/blueprint/components/ui/CloseButton.vue';
import Enquiry from '@/blueprint/components/search/Enquiry.vue';

import gql from 'graphql-tag';
import { Certificate } from '@/store/landlord/state';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { KeyValuePair } from '@lordly/models2/interfaces';

import { date } from 'quasar';

export default Vue.extend({
  name: 'MoreInfo-Page',
  components: {
    'loading': Loading,
    'close-btn': CloseButton,
    'enquiry': Enquiry,
  },
  data () {
    return {
      warnings: [] as Warning[],
      warningAnimate: false,
      warningAntimateInterval: -1,
      showWarning: false,
      propertyLoading: true,
      slide: 0,
      images: [] as string[],
      id: '',
      city: '',
      property: {
        images: [],
        available: false,
        address: {
          line1: '',
          line2: '',
          line3: '',
          line4: '',
          city: '',
          county: '',
          postcode: '',
          latlng: {
            lat: '',
            lng: '',
          },
        },
        details: {
          bedroom: 1,
          toilet: 1,
          ensuite: 'No',
          type: 'House',
        },
        dates: {
          tenancyStart: '',
          tenancyEnd: '',
          duration: {
            monthsRounded: '',
          },
        },
        rent: {
          computed: {
            weekly: '',
            monthly: '',
            yearly: '',
          },
        },
        deposit: {
          total: '',
          computed: {
            perperson: '',
          },
          scheme: 'No Deposit Scheme',
        },
        rentIncludes: [],
        billEstimates: [],
        fees: [],
        certificates: [],
        description: '',
      } as unknown as Partial<Property>,
    };
  },
  computed: {
    showBillEstimates (): boolean {
      const billsWithValues: KeyValuePair[] = this.property.billEstimates!.filter((bill) => {
        if (parseFloat(bill.value) > 0) {
          return bill;
        }
      });
      return billsWithValues.length > 0;
    },
    landlordId (): string {
      if (this.id) {
        return this.id.split('-')[0];
      }
      return '';
    },
  },
  created () {
    // Parse url variables
    const params: Record<string, string> = this.$route.params;
    if (params.id && params.city) {
      this.id  = params.id;
      this.city = params.city;
      // Track moreinfo
      this.$lga.TrackMoreInfo(this.id, this.city);
    } else {
      this.$router.push('/');
    }
    // Get property
    this.getProperty();
  },
  mounted () {
    this.warningAntimateInterval = window.setInterval(() => {
      this.warningAnimate = !this.warningAnimate;
    }, 1000);
  },
  beforeDestroy () {
    window.clearInterval(this.warningAntimateInterval);
  },
  // Methods
  methods: {
    async getProperty () {
      // Set loading to be true
      this.propertyLoading = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        query($id: String!, $partition: String!) {
          GetProperty (
            input: {
              id: $id,
              partition: $partition
            }
          ) {
            images {
              url
            }
            available
            address {
              line1
              line2
              line3
              line4
              city
              county
              postcode
              latlng {
                lat
                lng
              }
            }
            details {
              bedroom
              toilet
              ensuite
              type
            }
            dates {
              tenancyStart
              tenancyEnd
              duration {
                monthsRounded
              }
            }
            rent {
              computed {
                weekly
                monthly
                yearly
              }
            }
            deposit {
              total
              computed {
                perperson
              }
              scheme
            }
            rentIncludes {
              label
              value
            }
            billEstimates {
              label
              value
            }
            fees {
              label
              value
            }
            certificates {
              label
              ref
              expiry
            }
            description
          }
        }
      `;
      // Create query variables
      const payload: Partial<API.GetPropertyInput> = {
        id: this.id,
        partition: this.city,
      };
      // Send request
      try {
        const response: Partial<Property> = await this.$gql.Query<API.GetPropertyInput>('GetProperty', query, payload);
        this.property = response;
        // Map property
        this.mapProperty();
        // Handle property images
        this.images = this.property.images!.map((image) => image.url);
        // Setup placeholder image if no images
        if (this.images && this.images.length === 0) {
          this.images = [this.loadPlaceholderImage()];
        }
        // Validate property
        this.validateProperty();
      } catch (e) {
        console.error(e);
      } finally {
        this.propertyLoading = false;
      }
    },
    mapProperty () {
      // Create location object
      let latlng: any = { lat: this.property!.address!.latlng.lat, lng: this.property!.address!.latlng.lng };
      // Create the marker
      const marker: google.maps.Marker = new google.maps.Marker({
        position: latlng,
        title: this.property!.address!.line1,
        icon: '/img/app/icons/result-default.png',
        clickable: true,
      });
      // Set the map view options
      let mapOptions: any = {
        center: latlng,
        zoom: 14,
        scrollwheel: false,
        scaleControl: false,
        fullscreenControl: false,
        zoomControl: true,
      };
      // Add event listener to marker
      marker.addListener('click', () => {
        this.openGoogleMaps();
      });
      // Get map element reference
      const domElement: Element = this.$refs.map as Element;
      // Create map
      const map: google.maps.Map =  new google.maps.Map(domElement, mapOptions);
      // Set marker
      marker.setMap(map);
    },
    openGoogleMaps () {
      if (this.property.address!.latlng.lat && this.property.address!.latlng.lng) {
        const url: string = 'https://www.google.com/maps/search/?api=1&query=' + this.property.address!.latlng.lat + ', ' + this.property.address!.latlng.lng;
        window.open(url, '_blank');
      }
    },
    getPrettyDate (value: string) {
      return date.formatDate(new Date(value), 'Do MMMM YYYY');
    },
    viewPortfolio () {
      if (this.id) {
        const route: string = '/portfolio/' + this.landlordId;
        this.$router.push(route);
      }
    },
    validateProperty () {
      // Protection scheme
      if (this.property.deposit!.scheme === 'No Deposit Protection') {
        this.warn('No deposit protection', 'Ensure that your deposit is protected prior to renting the property');
      }

      // Validate - Certificates
      this.property.certificates!.forEach((cert) => {
        if (!cert.ref) {
           this.warn((cert.label + ' reference is not provided'), ('Ensure the landlord has a valid ' + cert.label + ' prior to renting the property'));
        } else {
          const advice: string = ('Ensure the landlord has renewed their ' + cert.label + ' prior to renting the property');
          if (!cert.expiry) {
            this.warn((cert.label + ' expiry is not provided'), advice);
          } else {
            if (new Date(cert.expiry) < new Date()) {
              this.warn((cert.label + ' has expired'), advice);
            }
          }
        }
      });
    },
    validateCertificate (cert: Certificate) {
      let validity: boolean = false;
      if (cert.ref && cert.expiry) {
        if (new Date(cert.expiry) > new Date()) {
          validity = true;
        }
      }
      return validity;
    },
    warn (reason: string, advice: string) {
      const newWarning: Warning = {
        reason,
        advice,
      };
      this.warnings.push(newWarning);
    },
    loadPlaceholderImage (): string {
      return window.location.origin + '/img/app/icons/placeholder-property-thumbnail.png';
    },
  },
});

interface Warning {
  reason: string;
  advice: string;
}
